import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './App.sass';

import ItensMenu from './components/menu/footer/itensMenu';

import Cabecalho from './components/cabecalho';
import Menu from './components/menu';
import Main from './components/main';
import Sobre from './components/sobre';
import Sistema from './components/sistema';
import Contato from './components/contato';
import AreaRestrita from './components/area_restrita';

import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom';

import Vendas from './views/vendas';
import Estoque from './views/estoque';
import Representantes from './views/representantes';
import NotFound from './views/notfound';

function RouterHook() {
  const closeModal = () => {
    const modal = document.querySelector('.modal');

    if (modal) {
      modal.classList.remove('is-active');

      const bloco_localizacao: any = document.querySelector('#modal_localizacao');
      if (bloco_localizacao) bloco_localizacao.style.display = 'none';

      const bloco_fone: any = document.querySelector('#modal_telefone');
      if (bloco_fone) bloco_fone.style.display = 'none';
    }
  }

  window.addEventListener('click', function (event: any) {
    if (event.target.className === 'modal-background') {
      closeModal();
    }
  })

  return (
    <Router>
      <div id="main">
        <Switch>
          <Route path="/" exact={true} component={App} />
          <Route path="/vendas" exact={true} component={Vendas} />
          <Route path="/estoque" exact={true} component={Estoque} />
          <Route path="/Representantes" exact={true} component={Representantes} />
          <Route path="/:handle" exact={true} component={Estoque} />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
      <div className="modal modal-fx-fadeInScale">
        <div className="modal-background"></div>
        <div className="modal-content">
          <div id="modal_localizacao" style={{ display: "none" }}>
            <div className="popover_address_icons">
              <div className="global-contact-details-container text-center">
                <ul className="list-unstyled">
                  <li>
                    <a href="http://maps.google.com?q=Londrina%2C+Parana%2C+Brazil">
                      <FontAwesomeIcon icon="location-arrow" size="sm" /> Londrina, Parana, Brazil</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="popover_icons">
              <div className="social-icons">
                <ul className="list-inline">
                  <li>
                    <a href="https://maps.google.com?q=Londrina%2C+Parana%2C+Brazil" rel="noopener noreferrer" target="_blank">
                      <img src="//cdn-cms-s.f-static.net/files/images/google_map_white_small.png?v=r6915" alt="google maps" style={{ width: "40px", height: "auto", paddingBottom: "7px" }} /></a>
                  </li>
                  <li>
                    <a href="https://waze.com/ul?ll=-23.3044524,-51.1695824&amp;navigate=yes" rel="noopener noreferrer" target="_blank">
                      <img src="//cdn-cms-s.f-static.net/files/images/waze_white_small.png?v=r6915" alt="waze" style={{ width: "40px", height: "auto", paddingBottom: "7px" }} /></a>
                  </li>
                  <li>
                    <a href="https://moovit.com/?to=Londrina%2C+Parana%2C+Brazil&amp;tll=-23.3044524_-51.1695824&amp;metroId=1" rel="noopener noreferrer" target="_blank">
                      <img src="//cdn-cms-s.f-static.net/files/images/moovit_white_small.png?v=r6915" alt="moovit" style={{ width: "40px", height: "auto", paddingBottom: "7px" }} /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="modal_telefone" style={{ display: "none" }}>
            <div className="popover_phone_icons">
              <div className="global-contact-details-container text-center">
                <ul className="list-unstyled">
                  <li>
                    <a href="tel:(43)99127-6734"><FontAwesomeIcon icon="phone" size="sm" /> <span dir="ltr">(43) 9 9127-6734</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => closeModal()}></button>
      </div>
    </Router>
  );
}

function App() {
  document.title = 'Max Posto';

  let params: any = useParams();

  switch (params.id) {
    case 'vendas':
      return <Vendas />;
    default:
      return (
        <div>
          <Cabecalho />
          <Menu />
          <section id='bemvindo'>
            <Main />
          </section>
          <section id='sobre'>
            <Sobre />
          </section>
          <section id='sistema'>
            <Sistema />
          </section>
          <section id='contato_sistema'>
            <Contato />
          </section>
          <section id='area_restrita'>
            <AreaRestrita />
          </section>
          <footer className="global_footer footer_1">
            <div className="container">
              <div className="row">
                <div className="side1 col-xs-12 col-sm-5 col-md-5">
                  <div>
                    <span className="footer_name website-name-preview-helper">Sistema de Gestão para Postos de Combustíveis</span>
                  </div>
                  <div>Direitos autorais © 2020 Todos os direitos reservados</div>
                </div>
                <div className="side2 col-xs-12 col-sm-7 col-md-7">
                  <ItensMenu />
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
  }
}

export default RouterHook;