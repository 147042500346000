import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Sistema extends Component {
    componentDidMount() { }

    render() {
        return (
            <div className='blocoSistema'>
                <div className='columns blocoTitulo'>
                    <div className='column is-12 tituloBloco'>
                        <h2>SISTEMA</h2>
                        <hr />
                    </div>
                </div>
                <div className='columns'>
                    <div className='column is-2 is-offset-1 modulo'>
                        <Link to="/vendas"><span id='modulo_vendas'></span></Link>
                        <p>Módulo Vendas</p>
                    </div>
                    <div className='column is-2 modulo'>
                        <Link to="/estoque"><span id='modulo_estoque'></span></Link>
                        <p>Módulo Estoque</p>
                    </div>
                    <div className='column is-2 modulo'>
                        <Link to="/financeiro"><span id='modulo_financeiro'></span></Link>
                        <p>Módulo Financeiro</p>
                    </div>
                    <div className='column is-2 modulo'>
                        <Link to="/contabil"><span id='modulo_contabil'></span></Link>
                        <p>Módulo Escrita Contábil</p>
                    </div>
                    <div className='column is-2 modulo'>
                        <Link to="/crm-bi"><span id='modulo_crm_bi'></span></Link>
                        <p>Módulo CRM e BI</p>
                    </div>
                </div>
                <div className='columns' style={{ paddingTop: 30 }}>
                    <div className='column is-2 is-offset-1 modulo'>
                        <Link to="/max-lub"><span id='modulo_max_lub'></span></Link>
                        <p>Módulo Max Lub</p>
                    </div>
                    <div className='column is-2 modulo'>
                        <Link to="/max-auto-pecas"><span id='modulo_max_auto'></span></Link>
                        <p>Módulo Max Auto Peças</p>
                    </div>
                    <div className='column is-2 modulo'>
                        <Link to="/max-frota-control"><span id='modulo_max_frota'></span></Link>
                        <p>Módulo Max Frota Control</p>
                    </div>
                    <div className='column is-2 modulo'>
                        <Link to="/max-dashboard"><span id='modulo_max_dashboard'></span></Link>
                        <p>Módulo Max Dashboard</p>
                    </div>
                    <div className='column is-2 modulo'>
                        <Link to="/restaurante"><span id='modulo_restaurante'></span></Link>
                        <p>Módulo de Restaurante</p>
                    </div>
                </div>
                <div className='columns' style={{ paddingTop: 30 }}>
                    <div className='column is-2 is-offset-1 modulo'>
                        <Link to="/cte"><span id='modulo_cte'></span></Link>
                        <p>Módulo Conhecimento de Transporte</p>
                    </div>
                    <div className='column is-2 modulo'>
                        <Link to="/pdv"><span id='modulo_pdv'></span></Link>
                        <p>PDV</p>
                    </div>
                    <div className='column is-2 modulo'>
                        <Link to="/fidelidade"><span id='modulo_clubefidelidade'></span></Link>
                        <p>Clube Fidelidade</p>
                    </div>
                    <div className='column is-2 modulo'>
                        <Link to="/esocial"><span id='modulo_esocial'></span></Link>
                        <p>eSocial</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sistema;