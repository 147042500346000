import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

import './representantes.css';
import MapaBrasil from './mapa';

import Icon02 from '../../assets/contato/escolhas-icon2.jpeg';
import Icon04 from '../../assets/contato/escolhas-icon4.jpeg';
import Icon05 from '../../assets/contato/escolhas-icon5.jpeg';

import ImgRepresentantes from '../../assets/img-representantes.jpeg';

type InputsGenerico = {
    nome: string,
    email: string,
    cnpj: string,
    cargo: string,
    ddd: string,
    telefone: string,
    cidade: string,
    estado: string,
    mensagem: string
};

type InputsTrial = {
    nome: string,
    email: string,
    cnpj: string,
    cargo: string,
    ddd: string,
    telefone: string,
    cidade: string,
    estado: string,
    erp_atual: string,
    erp: string,
    mensagem: string
};

type InputsBeRepresentative = {
    nome: string,
    email: string,
    empresa: string,
    colaboradores: string,
    ddd: string,
    telefone: string,
    cidade: string,
    estado: string,
    mensagem: string
};

type InputsNewsletter = {
    email: string
};

const Representantes = (props: any) => {
    const [secaoAtiva, setSecaoAtiva] = useState(1);
    const [sucess, setSucess] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const axios = require('axios').default;

    const { register: registerGenerico, handleSubmit: handleSubmitGenerico, setValue: setValueGenerico } = useForm<InputsGenerico>({ mode: "onBlur" });
    const { register: registerTrial, handleSubmit: handleSubmitTrial, setValue: setValueTrial, errors: errorsTrial } = useForm<InputsTrial>({ mode: "onBlur" });
    const { register: registerBeRepresentative, handleSubmit: handleSubmitBeRepresentative, setValue: setValueBeRepresentative, errors: errorsBeRepresentative } = useForm<InputsBeRepresentative>({ mode: "onBlur" });
    const { register: registerNewsletter, handleSubmit: handleSubmitNewsletter, setValue: setValueNewsletter } = useForm<InputsNewsletter>({ mode: "onBlur" });

    const onSubmitGenerico = (data: any) => {
        setIsButtonLoading(true);

        axios.post('/controllers/controllerFormGenerico.php', data, { responseType: 'json' })
            .then(function (response: any) {
                setValueGenerico('nome', '');
                setValueGenerico('email', '');
                setValueGenerico('cnpj', '');
                setValueGenerico('cargo', '');
                setValueGenerico('ddd', '');
                setValueGenerico('telefone', '');
                setValueGenerico('cidade', '');
                setValueGenerico('estado', '');
                setValueGenerico('mensagem', '');

                setSucess(true);
                setIsButtonLoading(false);

                const timer = setTimeout(() => {
                    setSucess(false);
                }, 5000);
                return () => clearTimeout(timer);
            })
            .catch(function (error: any) {
                setIsButtonLoading(false);
                console.log(`erro: ${error}`);
                alert('Ocorreu um erro ao enviar o contato.');
            });
    }

    const onSubmitTrial = (data: any) => {
        setIsButtonLoading(true);

        axios.post('/controllers/controllerFormTrial.php', data, { responseType: 'json' })
            .then(function (response: any) {
                setValueTrial('nome', '');
                setValueTrial('email', '');
                setValueTrial('cnpj', '');
                setValueTrial('cargo', '');
                setValueTrial('ddd', '');
                setValueTrial('telefone', '');
                setValueTrial('cidade', '');
                setValueTrial('estado', '');
                setValueTrial('erp_atual', '');
                setValueTrial('erp', '');
                setValueTrial('mensagem', '');

                setSucess(true);
                setIsButtonLoading(false);

                const timer = setTimeout(() => {
                    setSucess(false);
                }, 5000);
                return () => clearTimeout(timer);
            })
            .catch(function (error: any) {
                setIsButtonLoading(false);
                console.log(`erro: ${error}`);
                alert('Ocorreu um erro ao enviar o contato.');
            });
    }

    const onSubmitBeRepresentative = (data: any) => {
        setIsButtonLoading(true);

        axios.post('/controllers/controllerFormBeRepresentative.php', data, { responseType: 'json' })
            .then(function (response: any) {
                setValueBeRepresentative('nome', '');
                setValueBeRepresentative('email', '');
                setValueBeRepresentative('empresa', '');
                setValueBeRepresentative('colaboradores', '');
                setValueBeRepresentative('ddd', '');
                setValueBeRepresentative('telefone', '');
                setValueBeRepresentative('cidade', '');
                setValueBeRepresentative('estado', '');
                setValueBeRepresentative('mensagem', '');

                setSucess(true);
                setIsButtonLoading(false);

                const timer = setTimeout(() => {
                    setSucess(false);
                }, 5000);
                return () => clearTimeout(timer);
            })
            .catch(function (error: any) {
                setIsButtonLoading(false);
                console.log(`erro: ${error}`);
                alert('Ocorreu um erro ao enviar o contato.');
            });
    }

    const onSubmitNewsletter = (data: any) => {
        setIsButtonLoading(true);

        axios.post('/controllers/controllerFormNewsletter.php', data, { responseType: 'json' })
            .then(function (response: any) {
                setValueNewsletter('email', '');

                setSucess(true);
                setIsButtonLoading(false);

                const timer = setTimeout(() => {
                    setSucess(false);
                }, 5000);
                return () => clearTimeout(timer);
            })
            .catch(function (error: any) {
                setIsButtonLoading(false);
                console.log(`erro: ${error}`);
                alert('Ocorreu um erro ao enviar o contato.');
            });
    }

    const setState = (index: number) => {
        setSecaoAtiva(index);

        var elementMap: any = document.getElementsByClassName('mapa')[0];
        elementMap.style.display = index === 1 ? 'block' : 'none';
        var elementForm: any = document.getElementsByClassName('bloco-form generico')[0];
        elementForm.style.display = 'none';
    }

    const handleBtnVoltar = () => {
        var elementMap: any = document.getElementsByClassName('mapa')[0];
        elementMap.style.display = 'block';
        var elementForm: any = document.getElementsByClassName('bloco-form generico')[0];
        elementForm.style.display = 'none';
    }

    return (
        <div>
            <menu id="menu" className="">
                <div className="container">
                    <ul>
                        <li><Link to="/" ><FontAwesomeIcon icon="home" size="2x" /><span style={{ fontSize: 18 }}> INÍCIO</span></Link></li>
                    </ul>
                </div>
            </menu>

            <div className="clear" id="main">
                <section id="contato" className="representantes">
                    <div className="banner">
                        <span className="bg"></span>
                        <div className="container">
                            <div className="title">
                                <div style={{ marginBottom: 0, height: 50 }}>
                                    {/* <Link to="/" ><FontAwesomeIcon icon="home" size="2x" color="white" style={{ borderRadius: '50%', backgroundColor: 'black', padding: 10 }} /></Link> */}
                                </div>
                                <b>ENCONTRE UM DE NOSSOS</b>
                                <h2>Representantes</h2>
                                <div className="texto">
                                    <p>Escolha seu Estado e receba uma ligação de um de nossos representantes<br />ue atuam em sua localidade.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="escolhas">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <button id="btnProc" className={`escolha generico${secaoAtiva === 1 ? ' active' : ''}`} data-link="generico" data-title="1" data-mapa="true" data-mapping-form="procura-representantes" onClick={() => setState(1)}>
                                        <img src={Icon04} alt="" />
                                        <p> Procura um<br /> Representante? </p>
                                        <span className="solicitar"> SOLICITAR </span>
                                        <span className="line"></span>
                                    </button>
                                </div>
                                <div className="col-md-4">
                                    <button id="btnTrial" className={`escolha trial${secaoAtiva === 3 ? ' active' : ''}`} data-link="trial" data-title="2" data-mapping-form="trial" onClick={() => setState(3)}>
                                        <img src={Icon02} alt="" />
                                        <p> Quer Testar<br /> o Software? </p>
                                        <span className="solicitar"> SOLICITAR </span>
                                        <span className="line"></span>
                                    </button>
                                </div>
                                <div className="col-md-4">
                                    <button id="btnRepresentante" className={`escolha representantes representante${secaoAtiva === 2 ? ' active' : ''}`} data-title="3" data-link="representantes" data-mapping-form="representantes" onClick={() => setState(2)}>
                                        <img src={Icon05} alt="" />
                                        <p> Quer se Tornar<br /> Representante? </p>
                                        <span className="solicitar"> ACESSE </span>
                                        <span className="line"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="human" style={{ display: secaoAtiva === 1 ? 'block' : 'none' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="title">
                                        <h2>Nosso atendimento é especializado na gestão de postos, contamos com uma equipe treinada pronta para atendê-lo.</h2>
                                        <div className="texto">
                                            <p>
                                                Você também pode contar com o suporte local através de nossos representantes.<br />
                                                Nosso atendimento é 24H, 7 dias na semana.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="image">
                                        <span className="borda"><b></b></span>
                                        <img src={ImgRepresentantes} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="cont">
                        <div className="container">
                            <div className="row row-perso">
                                <div className="col-lg-6 col-md-12 col-infos">
                                    <div className="informacoes">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-6">
                                                <div className="title title-1" style={{ display: secaoAtiva === 1 ? 'block' : 'none' }}>
                                                    <h2>Desejando mais informações, informe seus dados que um de nossos representantes irá ligar para você, e tirar todas as dúvidas.</h2>
                                                    <div className="texto">
                                                        <p>
                                                            Preencha o formulário ao lado para que possamos entrar em contato com você e conversaremos sobre suas necessidades, 
                                                            ou se preferir, entre em contato utilizando uma das formas abaixo:
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="title title-2" style={{ display: secaoAtiva === 3 ? 'block' : 'none' }}>
                                                    <h2>Solicite uma demonstração gratuita.</h2>
                                                    <div className="texto">
                                                        <p>
                                                            Preencha o formulário ao lado para que possamos entrar em contato com você e dar andamento ao inicio de seu periodo de teste gratuito, 
                                                            ou se preferir, entre em contato utilizando uma das formas abaixo:
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="title title-3" style={{ display: secaoAtiva === 2 ? 'block' : 'none' }}>
                                                    <h2>Preencha com seus dados que um dos nossos representantes entrará em contato.</h2>
                                                    <div className="texto">
                                                        <p>
                                                            Preencha o formulário ao lado para que possamos entrar em contato com você e conversaremos com você sobre seu mercado e localidade, 
                                                            ou se preferir, entre em contato utilizando uma das formas abaixo:
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-6">
                                                <div className="infos">
                                                    <p className="fone">
                                                        <img src="https://www.gruposgapetro.com.br/img/contato/infos-icon1.png" alt="" />
                                                    +55 43 <span>9 9127-6734</span>
                                                    </p>
                                                    <p className="email">
                                                        <img src="https://www.gruposgapetro.com.br/img/contato/infos-icon2.png" alt="" />
                                                        contato@maxposto.com
                                                    </p>
                                                    {/* <p>
                                                    <img src="https://www.gruposgapetro.com.br/img/contato/infos-icon3.png" alt="" />
                                                    Avenida Pedro Taques, 294<br />
                                                    Ed. Átrium - Sala 1202 - 87050-110<br />
                                                    Maringá - PR
                                                </p> */}
                                                    <p>
                                                        <img src="https://www.gruposgapetro.com.br/img/contato/infos-icon4.png" alt="" />
                                                    De segunda a sexta de 7h às 18h<br />
                                                    Atendimento suporte: 24hs
                                                </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12 col-form">
                                    <input type="hidden" className="active-form" value="trial" />
                                    <input type="hidden" className="city-representante" />

                                    <div className="bloco-form generico" style={{ display: 'none' }}>
                                        <div className="title">
                                            <h2>
                                                Fale Conosco
                                                <button onClick={handleBtnVoltar} className="botao-voltar-form btn-simple green"> <img src="https://www.gruposgapetro.com.br/img/contato/flexa-coltar.png" alt="" style={{ marginRight: 5 }} /> VOLTAR</button>
                                            </h2>
                                            <div className="texto">
                                                <p>
                                                    Digite seus dados abaixo para identificarmos sua necessidade.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="forms">
                                            <form className="forms-contatos" onSubmit={handleSubmitGenerico(onSubmitGenerico)}>
                                                <input type="text" name="nome" placeholder="Seu Nome *" ref={registerGenerico({ required: true })} />
                                                <input type="email" name="email" placeholder="Seu Email *" ref={registerGenerico({ required: true })} />
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <input type="text" name="cnpj" placeholder="Seu CNPJ *" ref={registerGenerico({ required: true })} />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <select className="" name="cargo" ref={registerGenerico({ required: true })}>
                                                            <option> Cargo </option>
                                                            <option value="Diretor"> Diretor </option>
                                                            <option value="Gerente"> Gerente </option>
                                                            <option value="Financeiro"> Financeiro </option>
                                                            <option value="Caixa"> Caixa </option>
                                                            <option value="Frentista"> Frentista </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <input type="text" name="ddd" placeholder="DDD *" max="2" ref={registerGenerico({ required: true })} />
                                                    </div>
                                                    <div className="col-md-9">
                                                        <input type="text" name="telefone" placeholder="Telefone *" ref={registerGenerico({ required: true })} max="9" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <input type="text" name="cidade" placeholder="Cidade *" ref={registerGenerico({ required: true })} />
                                                    </div>
                                                    <div className="col-md-5">
                                                        <select name="estado">
                                                            <option value="">Estado</option>
                                                            <option value="AC">Acre</option>
                                                            <option value="AL">Alagoas</option>
                                                            <option value="AP">Amapá</option>
                                                            <option value="AM">Amazonas</option>
                                                            <option value="BA">Bahia</option>
                                                            <option value="CE">Ceará</option>
                                                            <option value="DF">Distrito Federal</option>
                                                            <option value="ES">Espírito Santo</option>
                                                            <option value="GO">Goiás</option>
                                                            <option value="MA">Maranhão</option>
                                                            <option value="MT">Mato Grosso</option>
                                                            <option value="MS">Mato Grosso do Sul</option>
                                                            <option value="MG">Minas Gerais</option>
                                                            <option value="PA">Pará</option>
                                                            <option value="PB">Paraíba</option>
                                                            <option value="PR">Paraná</option>
                                                            <option value="PE">Pernambuco</option>
                                                            <option value="PI">Piauí</option>
                                                            <option value="RJ">Rio de Janeiro</option>
                                                            <option value="RN">Rio Grande do Norte</option>
                                                            <option value="RS">Rio Grande do Sul</option>
                                                            <option value="RO">Rondônia</option>
                                                            <option value="RR">Roraima</option>
                                                            <option value="SC">Santa Catarina</option>
                                                            <option value="SP">São Paulo</option>
                                                            <option value="SE">Sergipe</option>
                                                            <option value="TO">Tocantins</option>
                                                            <option value="EX">Estrangeiro</option>
                                                        </select>
                                                        {errorsBeRepresentative.estado && <span>Campo obrigatório.</span>}
                                                    </div>
                                                </div>
                                                <textarea name="mensagem" placeholder="Mensagem" ref={registerGenerico({ required: true })}></textarea>
                                                <div className="botao">
                                                    <button type="submit" className="btn-simple green">{isButtonLoading ? <FontAwesomeIcon icon="circle-notch" size='lg' spin /> : " ENVIAR "}</button>
                                                </div>
                                            </form>
                                            {sucess && <span style={{ display: 'flex', justifyContent: 'center', fontSize: 20, color: 'green', fontWeight: 'bold', paddingTop: 12 }}>Mensagem enviada com sucesso!</span>}
                                        </div>
                                    </div>
                                    <div className="bloco-form representantes" style={{ display: secaoAtiva === 2 ? 'block' : 'none' }}>
                                        <div className="title">
                                            <h2>
                                                Deseja Entrar Para o Time?
                                        </h2>
                                            <div className="texto">
                                                <p>
                                                    Digite seus dados abaixo para lhe fazermos uma proposta.
                                            </p>
                                            </div>
                                        </div>
                                        <div className="forms">
                                            <form className="forms-contatos" onSubmit={handleSubmitBeRepresentative(onSubmitBeRepresentative)}>
                                                <input type="text" name="nome" placeholder="Seu Nome *" ref={registerBeRepresentative({ required: true })} />
                                                <input type="email" name="email" placeholder="Seu Email *" ref={registerBeRepresentative({ required: true })} />
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <input type="text" name="empresa" placeholder="Empresa *" ref={registerBeRepresentative({ required: true })} />
                                                    </div>
                                                    <div className="col-md-5">
                                                        <select className="" name="colaboradores" ref={registerBeRepresentative({ required: true })}>
                                                            <option> Colaboradores </option>
                                                            <option value="1-5"> 1-5 </option>
                                                            <option value="5-20"> 5-20 </option>
                                                            <option value="20-50"> 20-50 </option>
                                                            <option value="50-100"> 50-100 </option>
                                                            <option value="+100"> +100 </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <input type="text" name="ddd" placeholder="DDD *" ref={registerBeRepresentative({ required: true })} />
                                                    </div>
                                                    <div className="col-md-9">
                                                        <input type="text" name="telefone" placeholder="Telefone *" ref={registerBeRepresentative({ required: true })} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <input type="text" name="cidade" placeholder="Cidade *" ref={registerBeRepresentative({ required: true })} />
                                                    </div>
                                                    <div className="col-md-5">
                                                        <select name="estado" ref={registerBeRepresentative({ required: true })}>
                                                            <option value="">Estado</option>
                                                            <option value="AC">Acre</option>
                                                            <option value="AL">Alagoas</option>
                                                            <option value="AP">Amapá</option>
                                                            <option value="AM">Amazonas</option>
                                                            <option value="BA">Bahia</option>
                                                            <option value="CE">Ceará</option>
                                                            <option value="DF">Distrito Federal</option>
                                                            <option value="ES">Espírito Santo</option>
                                                            <option value="GO">Goiás</option>
                                                            <option value="MA">Maranhão</option>
                                                            <option value="MT">Mato Grosso</option>
                                                            <option value="MS">Mato Grosso do Sul</option>
                                                            <option value="MG">Minas Gerais</option>
                                                            <option value="PA">Pará</option>
                                                            <option value="PB">Paraíba</option>
                                                            <option value="PR">Paraná</option>
                                                            <option value="PE">Pernambuco</option>
                                                            <option value="PI">Piauí</option>
                                                            <option value="RJ">Rio de Janeiro</option>
                                                            <option value="RN">Rio Grande do Norte</option>
                                                            <option value="RS">Rio Grande do Sul</option>
                                                            <option value="RO">Rondônia</option>
                                                            <option value="RR">Roraima</option>
                                                            <option value="SC">Santa Catarina</option>
                                                            <option value="SP">São Paulo</option>
                                                            <option value="SE">Sergipe</option>
                                                            <option value="TO">Tocantins</option>
                                                            <option value="EX">Estrangeiro</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <textarea name="mensagem" placeholder="Mensagem" ref={registerBeRepresentative({ required: true })}></textarea>
                                                <div className="botao">
                                                    <button type="submit" className="btn-simple green">{isButtonLoading ? <FontAwesomeIcon icon="circle-notch" size='lg' spin /> : " ENVIAR "}</button>
                                                </div>
                                            </form>
                                            {sucess && <span style={{ display: 'flex', justifyContent: 'center', fontSize: 20, color: 'green', fontWeight: 'bold', paddingTop: 12 }}>Mensagem enviada com sucesso!</span>}
                                        </div>
                                    </div>
                                    <div className="bloco-form trial" style={{ display: secaoAtiva === 3 ? 'block' : 'none' }}>
                                        <div className="title">
                                            <h2>
                                                Cadastro Para Teste Gratuito
                                        </h2>
                                            <div className="texto">
                                                <p>
                                                    Digite seus dados abaixo para prosseguir seu teste gratuito
                                            </p>
                                            </div>
                                        </div>
                                        <div className="forms">
                                            <form className="forms-contatos" onSubmit={handleSubmitTrial(onSubmitTrial)}>
                                                <input type="text" name="nome" placeholder="Seu Nome *" ref={registerTrial({ required: true })} />
                                                <input type="email" name="email" placeholder="Seu Email *" ref={registerTrial({ required: true })} />
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <input type="text" name="cnpj" placeholder="Seu CNPJ *" ref={registerTrial({ required: true })} />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <select className="" name="cargo" ref={registerTrial({ required: true })}>
                                                            <option> Cargo </option>
                                                            <option value="Diretor"> Diretor </option>
                                                            <option value="Gerente"> Gerente </option>
                                                            <option value="Financeiro"> Financeiro </option>
                                                            <option value="Caixa"> Caixa </option>
                                                            <option value="Frentista"> Frentista </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <input type="text" name="ddd" placeholder="DDD *" ref={registerTrial({ required: true })} />
                                                    </div>
                                                    <div className="col-md-9">
                                                        <input type="text" name="telefone" placeholder="Telefone *" ref={registerTrial({ required: true })} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <input type="text" name="cidade" placeholder="Cidade *" ref={registerTrial({ required: true })} />
                                                    </div>
                                                    <div className="col-md-5">
                                                        <select name="estado" ref={registerTrial({ required: true })}>
                                                            <option value="">Estado</option>
                                                            <option value="AC">Acre</option>
                                                            <option value="AL">Alagoas</option>
                                                            <option value="AP">Amapá</option>
                                                            <option value="AM">Amazonas</option>
                                                            <option value="BA">Bahia</option>
                                                            <option value="CE">Ceará</option>
                                                            <option value="DF">Distrito Federal</option>
                                                            <option value="ES">Espírito Santo</option>
                                                            <option value="GO">Goiás</option>
                                                            <option value="MA">Maranhão</option>
                                                            <option value="MT">Mato Grosso</option>
                                                            <option value="MS">Mato Grosso do Sul</option>
                                                            <option value="MG">Minas Gerais</option>
                                                            <option value="PA">Pará</option>
                                                            <option value="PB">Paraíba</option>
                                                            <option value="PR">Paraná</option>
                                                            <option value="PE">Pernambuco</option>
                                                            <option value="PI">Piauí</option>
                                                            <option value="RJ">Rio de Janeiro</option>
                                                            <option value="RN">Rio Grande do Norte</option>
                                                            <option value="RS">Rio Grande do Sul</option>
                                                            <option value="RO">Rondônia</option>
                                                            <option value="RR">Roraima</option>
                                                            <option value="SC">Santa Catarina</option>
                                                            <option value="SP">São Paulo</option>
                                                            <option value="SE">Sergipe</option>
                                                            <option value="TO">Tocantins</option>
                                                            <option value="EX">Estrangeiro</option>
                                                        </select>
                                                        {errorsTrial.estado && <span>Campo obrigatório.</span>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <input type="text" name="erp_atual" placeholder="Qual seu Sistema de Gestão Atual?" ref={registerTrial({ required: true })} />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <p className="checkbox">
                                                            Nao tenho
                                                        <input type="checkbox" name="erp" value="Nao tenho" ref={registerTrial({ required: false })} />
                                                        </p>
                                                    </div>
                                                </div>
                                                <textarea name="mensagem" placeholder="Mensagem" ref={registerTrial({ required: true })}></textarea>
                                                <div className="botao">
                                                    <button type="submit" className="btn-simple green">{isButtonLoading ? <FontAwesomeIcon icon="circle-notch" size='lg' spin /> : " ENVIAR "}</button>
                                                </div>
                                            </form>
                                            {sucess && <span style={{ display: 'flex', justifyContent: 'center', fontSize: 20, color: 'green', fontWeight: 'bold', paddingTop: 12 }}>Mensagem enviada com sucesso!</span>}
                                        </div>
                                    </div>
                                    <div className="mapa" style={{ display: 'block' }}>
                                        <div className="title">
                                            <h2>
                                                Escolha seu Estado
                                            </h2>
                                        </div>
                                        <div>
                                            <MapaBrasil />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="cases" style={{ display: 'block' }}>
                        <section className="newsletter">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="desc">
                                            <img src="https://www.gruposgapetro.com.br/img/icon-newsletter.png" alt="" />
                                            <p> Cadastre-se e receba novidades e dicas para otimizar seus resultados </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <form className="forms-newsletter" onSubmit={handleSubmitNewsletter(onSubmitNewsletter)}>
                                            <input type="email" name="email" placeholder="Digite seu e-mail aqui" ref={registerNewsletter({ required: true })} />
                                            <button type="submit">{isButtonLoading ? <FontAwesomeIcon icon="circle-notch" size='lg' spin /> : "ENVIAR"}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <span className="bg-newsletter"></span>
                    </div>
                </section >
            </div >
        </div>
    )
}

export default Representantes;