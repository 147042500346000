import React, { Component } from 'react';

// import ContactButton from './botaoContato';
import './main.scss';

class Main extends Component {
    componentDidMount() { }

    render() {
        return (
            <div className='parallax'>
                {/* <div className="boxBorder ele_align_center">
                    <h1 className="weight400 shadow1">Bem-vindo ao</h1>
                    <h2 className="weight400 shadow1">Sistema Max Posto</h2>
                    <p className="weight400 shadow1">Sistema de Gestão de Postos de Combustíveis, Controle de Frotas.</p>
                    <ContactButton />
                </div> */}
            </div>
        )
    }
}

export default Main;