import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NotFound = () => {
    return (
        <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center", height: "600px" }}>
            <p>Desculpe, esse caminho não foi localizado.</p>
            <span style={{ fontSize: "32px", fontWeight: 700, color: "blue" }}>404 - Página não encontrada</span>
            <p style={{ marginTop: 20 }}>
                <FontAwesomeIcon icon="home" style={{ marginRight: 5, color: "black" }} />
                <Link to="/" style={{ fontWeight: "bold", color: "black" }}>Início</Link>
            </p>
        </div>
    )
}

export default NotFound;