import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import './menu.scss';
import ItensMenu from './header/itensMenu';

library.add(fas);

const Menu = (props: any) => {
    return (
        <div className='columns rootMenu'>
            <div className='column is-3'></div>
            <div className='column is-6'>
                <ItensMenu />
            </div>
            <div className='column is-3'></div>
        </div>
    )
}

export default Menu;