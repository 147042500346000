import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

class ItensMenu extends Component {
    componentDidMount() { }

    render() {
        return (
            <ul className="navPages nav navbar-nav hidden-xs">
                <li className="moduleMenu active">
                    {/* <a className="page-scroll homepageMenu" href="#main"><span className="txt-container">Início</span></a> */}
                    <AnchorLink href='#main'>INÍCIO</AnchorLink>
                </li>
                <li className="moduleMenu" data-menu-module-id="5b1cf2c16ca47">
                    <AnchorLink href='#sobre'>SOBRE</AnchorLink>
                </li>
                <li className="moduleMenu" data-menu-module-id="5b1cf2c198cb4">
                    <AnchorLink href='#sistema'>SISTEMA</AnchorLink>
                </li>
                <li className="moduleMenu" data-menu-module-id="5b1cf2c185c7d">
                    <AnchorLink href='#contato_sistema'>CONTATO</AnchorLink>
                </li>
                <li className="moduleMenu" data-menu-module-id="5f4d46c599c94">
                    <AnchorLink href='#area_restrita'>ÁREA RESTRITA</AnchorLink>
                </li>
                <li className="moduleMenu" data-menu-module-id="5f4d46c599c94">
                    <Link to="/representantes">REPRESENTANTES</Link>
                </li>
            </ul>
        )
    }
}

export default ItensMenu;