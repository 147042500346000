import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'react-router-dom';

class ItensMenu extends Component {
    componentDidMount() { }

    render() {
        return (
            <nav className="level-left navMenu" role="navigation" aria-label="main navigation">
                <p className="level-item has-text-centered itemMenu">
                    <AnchorLink href='#main'><FontAwesomeIcon icon="home" size="lg" style={{ marginRight: 6 }} />INÍCIO</AnchorLink>
                </p>
                <p className="level-item has-text-centered itemMenu">
                    <AnchorLink href='#sobre'><FontAwesomeIcon icon="info-circle" size="lg" style={{ marginRight: 6 }} />SOBRE</AnchorLink>
                </p>
                <p className="level-item has-text-centered itemMenu">
                    <AnchorLink href='#sistema'><FontAwesomeIcon icon="gas-pump" size="lg" style={{ marginRight: 6 }} />SISTEMA</AnchorLink>
                </p>
                <p className="level-item has-text-centered itemMenu">
                    <AnchorLink href='#contato_sistema'><FontAwesomeIcon icon="envelope" size="lg" style={{ marginRight: 6 }} />CONTATO</AnchorLink>
                </p>
                <p className="level-item has-text-centered itemMenu">
                    <AnchorLink href='#area_restrita'><FontAwesomeIcon icon="user" size="lg" style={{ marginRight: 6 }} />ÁREA RESTRITA</AnchorLink>
                </p>
                <p className="level-item has-text-centered itemMenu">
                    <Link to="/representantes"><FontAwesomeIcon icon="address-card" size="lg" style={{ marginRight: 6 }} />REPRESENTANTES</Link>
                </p>
                <p className="level-item has-text-centered">
                    {/* <a href='/localizacao' className="link"><FontAwesomeIcon icon="location-arrow" /></a> */}
                    <span onClick={onLocalizacao} style={{ cursor: "pointer" }}><FontAwesomeIcon icon="location-arrow" /></span>
                </p>
                <p className="level-item has-text-centered">
                    {/* <a href='/telefones' className="link"><FontAwesomeIcon icon="phone" /></a> */}
                    <span onClick={onTelefone} style={{ cursor: "pointer" }}><FontAwesomeIcon icon="phone" /></span>
                </p>
                {/* <p className="level-item has-text-centered">
                    <a href='/usuario' className="link"><FontAwesomeIcon icon="user" /></a>
                    <Link to="/usuario"><FontAwesomeIcon icon="user" /></Link>
                </p> */}
            </nav>
        )
    }
}

function onLocalizacao() {
    const modal = document.querySelector('.modal');
    const bloco = document.querySelector('#modal_localizacao');

    if (modal) {
        modal.classList.add('is-active');
        bloco.style.display = 'block';
    }
}

function onTelefone() {
    const modal = document.querySelector('.modal');
    const bloco = document.querySelector('#modal_telefone');

    if (modal) {
        modal.classList.add('is-active');
        bloco.style.display = 'block';
    }
}

export default ItensMenu;