import React from 'react';

import './estoque.scss';
import Cabecalho from '../../components/cabecalho';

const Estoque = (props: any) => {
    return (
        <div>
            <Cabecalho />
            <div className='view-estoque'>
                <h1>{props.match.params?.handle ?? 'Estoque'}</h1>
            </div>
        </div>
    )
}

export default Estoque;