import React, { Component } from 'react';

class Sobre extends Component {
    componentDidMount() { }

    render() {
        return (
            <div className='blocoSobre'>
                <div className='columns blocoTitulo'>
                    <div className='column is-12 tituloBloco'>
                        <h2>SOBRE</h2>
                        <hr />
                    </div>
                </div>
                <div className='columns'>
                    <div className='column is-4 is-offset-2 blocoImagem'>
                        <img alt='Sobre' src='https://cdn-cms.f-static.net/uploads/4160915/800_5f4d3afcc2c88.jpg' />
                    </div>
                    <div className='column is-4 blocoTexto'>
                        <p>
                            Somos uma empresa especializada em Sistema de Gestão para postos de Combustíveis e automação de bombas. Buscando sempre proporcionar o melhor funcionamento do posto, para que vc possa ter me mãos os dados corretos para tomar a decisão certa.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sobre;