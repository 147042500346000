import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Inputs = {
    email: string,
    senha: string
};

const AreaRestrita = () => {
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const { register, handleSubmit, setValue, errors } = useForm<Inputs>();

    const onSubmit = (data: any) => {
        setIsButtonLoading(true);

        const timer = setTimeout(() => {
            setValue('email', '');
            setValue('senha', '');

            setIsButtonLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }

    return (
        <div className='blocoAreaRestrita'>
            <div className='columns blocoTitulo'>
                <div className='column is-12 tituloBloco'>
                    <h2>ÁREA RESTRITA</h2>
                    <hr />
                </div>
            </div>
            <div className='container'>
                <div className="box" style={{ paddingTop: 50, paddingBottom: 50 }}>
                    <div className='columns'>
                        <div className="column is-10 is-offset-1">
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="field">
                                    <p className="control has-icons-left has-icons-right">
                                        <input className="input is-hovered" type="email" placeholder="E-mail" name="email" ref={register({ required: true })} />
                                        {errors.email && <span>O campo E-mail é obrigatório.</span>}
                                        <span className="icon is-small is-left">
                                            <FontAwesomeIcon icon="envelope" />
                                        </span>
                                        {/* <span className="icon is-small is-right">
                                            <FontAwesomeIcon icon="check" />
                                        </span> */}
                                    </p>
                                </div>
                                <div className="field">
                                    <p className="control has-icons-left">
                                        <input className="input is-hovered" type="password" placeholder="Senha" name="senha" ref={register({ required: true })} />
                                        {errors.senha && <span>O campo Senha é obrigatório.</span>}
                                        <span className="icon is-small is-left">
                                            <FontAwesomeIcon icon="lock" />
                                        </span>
                                    </p>
                                </div>
                                {/* <div className="field">
                                    <p className="control">
                                        <input name="email" className="input is-hovered" type="text" placeholder="Endereço de e-mail" ref={register({ required: true })} />
                                        {errors.email && <span>O campo E-mail é obrigatório.</span>}
                                    </p>
                                </div> */}
                                <div className="buttons">
                                    <button className="button is-primary is-fullwidth">{isButtonLoading ? <FontAwesomeIcon icon="circle-notch" size='lg' spin /> : "ENTRAR"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AreaRestrita;