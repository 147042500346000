import React, { Component } from 'react';

import './cabecalho.scss';

class Cabecalho extends Component {
    componentDidMount() { }

    render() {
        return (
            <div className='columns rootCabec'>
                <div className='column is-3'></div>
                <div className='column is-6'>
                    <div className='level-left allCabec'>
                        <img className='level-item imagemLogo' alt="Sistema de Gestão para Postos de Combustíveis" src="https://cdn-cms.f-static.net/uploads/4160915/400_5f4d36c8d3f13.png" />
                        <h1 className='level-item tituloCabec'>Sistema de Gestão para Postos de Combustíveis</h1>
                    </div>
                </div>
                <div className='column is-3'></div>
            </div>
        )
    }
}

export default Cabecalho;