import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Inputs = {
    nome: string,
    telefone: string,
    email: string,
    mensagem: string
};

const Contato = () => {
    const [sucess, setSucess] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const { register, handleSubmit, setValue, errors } = useForm<Inputs>();
    const axios = require('axios').default;

    const onSubmit = (data: any) => {
        setIsButtonLoading(true);

        axios.post('/controllers/controllerForm.php', data, { responseType: 'json' })
            .then(function (response: any) {
                setValue('nome', '');
                setValue('telefone', '');
                setValue('email', '');
                setValue('mensagem', '');

                setSucess(true);
                setIsButtonLoading(false);

                const timer = setTimeout(() => {
                    setSucess(false);
                }, 5000);
                return () => clearTimeout(timer);
            })
            .catch(function (error: any) {
                setIsButtonLoading(false);
                console.log(`erro: ${error}`);
                alert('Ocorreu um erro ao enviar o contato.');
            });
    }

    return (
        <div className='blocoContato'>
            <div className='columns blocoTitulo'>
                <div className='column is-12 tituloBloco'>
                    <h2>CONTATO</h2>
                    <hr />
                </div>
            </div>
            <div className='columns'>
                <div className='column is-6 blocoEndereco'>
                    <p>Londrina, Parana, Brazil</p>
                    <a href="tel:(43)99127-6734">
                        <FontAwesomeIcon icon="phone-alt" transform="shrink-4 left-4 down-2" />
                        <span>(43) 9 9127-6734</span>
                    </a>
                    <a href="mailto:adm@maxposto.com">
                        <FontAwesomeIcon icon="envelope" transform="shrink-4 left-4 down-2" />adm@maxposto.com
                    </a>
                    <p>
                        <FontAwesomeIcon icon="clock" transform="shrink-4 left-4 down-2" />07:30 as 19:00
                    </p>
                </div>
                <div className='column is-4 blocoForm'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="field is-horizontal">
                            <div className="field-body">
                                <div className="field">
                                    <p className="control">
                                        <input name="nome" className="input is-hovered" type="text" placeholder="Nome" ref={register({ required: true })} />
                                        {errors.nome && <span>O campo Nome é obrigatório.</span>}
                                    </p>
                                </div>
                                <div className="field">
                                    <p className="control">
                                        <input name="telefone" className="input is-hovered" type="text" placeholder="Telefone" ref={register({ required: true })} />
                                        {errors.telefone && <span>O campo Telefone é obrigatório.</span>}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <p className="control">
                                <input name="email" className="input is-hovered" type="text" placeholder="Endereço de e-mail" ref={register({ required: true })} />
                                {errors.email && <span>O campo E-mail é obrigatório.</span>}
                            </p>
                        </div>
                        <div className="field">
                            <p className="control">
                                <textarea name="mensagem" className="textarea is-hovered" placeholder="Mensagem" ref={register({ required: true })}></textarea>
                                {errors.mensagem && <span>O campo Mensagem é obrigatório.</span>}
                            </p>
                        </div>
                        <div className="buttons">
                            <button className="button is-primary is-fullwidth">{isButtonLoading ? <FontAwesomeIcon icon="circle-notch" size='lg' spin /> : "FALE CONOSCO"}</button>
                        </div>
                    </form>
                    {sucess && <span style={{ display: 'flex', justifyContent: 'center', fontSize: 20, color: 'green', fontWeight: 'bold', paddingTop: 12 }}>Mensagem enviada com sucesso!</span>}
                </div>
            </div>
            {/* <div className='containerMap'>
                <iframe title='Localização' src="https://maps.site123.com/include/globalMapDisplay.php?q=Londrina%2C+Parana%2C+Brazil&amp;z=15&amp;l=pt&amp;ilfc=1" />
            </div> */}
        </div>
    )
}

export default Contato;