import React from 'react';

import './vendas.scss';
import Cabecalho from '../../components/cabecalho';

const Vendas = () => {
    return (
        <div>
            <Cabecalho />
            <div className='view-vendas'>
                <h1>Vendas</h1>
            </div>
        </div>
    )
}

export default Vendas;