import React from 'react';
import ReactDOM from 'react-dom';
// import { BrowserRouter, Switch, Route } from 'react-router-dom';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './App';
// import Vendas from './views/vendas';
// import Estoque from './views/estoque';
// import NotFound from './views/notfound';

import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: "https://e4e23e7da16d4ee19c3d41039ae18ae2@o463176.ingest.sentry.io/5467999",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  // <BrowserRouter>
  //   <Switch>
  //     <Route path="/" exact={true} component={App} />
  //     <Route path="/vendas" component={Vendas} />
  //     <Route path="/estoque" component={Estoque} />
  //     <Route path="*" component={NotFound} />
  //   </Switch>
  // </ BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
